import { canAccess } from '../../auth/utils'

const menus = [
  {
    header: 'SOBEMAP',
  },
  {
    title: 'Tableau de bord',
    route: 'app-home',
    icon: 'HomeIcon',
    canShow: canAccess(['ROLE_USER']),
  },
  {
    header: 'Actualités',
    canShow: canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_DIR_COM']),
  },
  {
    title: 'Nouvelle actualité',
    route: 'app-news',
    icon: 'CastIcon',
    canShow: canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_DIR_COM']),
  },
  {
    title: 'Liste',
    route: 'app-news-list',
    icon: 'LayersIcon',
    canShow: canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_DIR_COM']),
  },
  {
    header: "Appel d'offre",
    canShow: canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_DIR_COM']),

  },
  {
    title: 'Nouvelle offre',
    route: 'app-tenders',
    icon: 'PhoneForwardedIcon',
    canShow: canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_DIR_COM']),

  },
  {
    title: 'Liste des offres',
    route: 'app-tenders-list',
    icon: 'LayersIcon',
    canShow: canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_DIR_COM']),

  },
  {
    header: 'Demande de Cotation',
    canShow: canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_OPERATION']),

  },
  {
    title: 'Liste des demandes',
    route: 'app-quote',
    icon: 'ListIcon',
    canShow: canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_OPERATION']),

  },
  {
    header: 'Mouvement Navire',
    canShow: canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_PREVISION_NAVIRE']),

  },
  {
    title: 'Nouveau',
    route: 'app-ship-movement-add',
    icon: 'RepeatIcon',
    canShow: canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_PREVISION_NAVIRE']),

  },
  {
    title: 'Gérer',
    route: 'app-ship-movement-listing',
    icon: 'MenuIcon',
    canShow: canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_PREVISION_NAVIRE']),

  },
  {
    header: 'Système',
    canShow: canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']),
  },
  {
    title: 'Parametres',
    route: 'app-settings',
    icon: 'SettingsIcon',
    canShow: canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']),
  },
]

export default menus
