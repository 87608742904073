import { logoutUser } from '@/auth/utils'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { response } = error

        // if (status === 401) {
        if (response && response.status === 401) {
          logoutUser()
        }
        return Promise.reject(error)
      },
    )
  }
}
